<template>
  <div>
    <div class="row">
      <div class="col-10"></div>
      <div class="col-2">
        <input v-model="searchQuery" type="text" class="form-control form-control-sm" @input="searchData" placeholder="Search" />
      </div>
    </div>
    <div class="form-row">
      <!-- form type quotation -->
      <div v-for="(item, k) in listBuying.data" :key="k" class="col-md-12 mt-2">
        <div class="card border">
          <div class="card-header" style="background: #d8f8eb;">
            <div class="row">
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-12">
                    <label for="">Vendor Invoice / Settlement Number: <span style="color: #3A1AFF;font-weight: 600;cursor:pointer;" @click="onAction('Back')">{{item.pi_number}}</span></label>
                  </div>
                </div>
              </div>
              <div class="col-md-6" style="display: flex;align-items: center;justify-content: end;">
                <span>PPN: </span> <span> {{formatRupiah((item.totalPPN).toString(), item.symbol_default + '. ')}}</span> <span style="margin-left: 10px;">PPH: </span> <span> {{formatRupiah((item.totalPPH).toString(), item.symbol_default + '. ')}}</span> <span style="margin-left: 10px;">GRAND TOTAL: </span> <span> {{formatRupiah((item.grandTotal).toString(), item.symbol_default + '. ')}} </span>
                <!-- <a href="javascript:void(0)" class="btn btn-sm ml-2" style="background: #009FC2;float: right;" @click="submitToAolPI(item.id_jobfile_cost)" v-show="companyIsAol === 1 && (item.id_pi_aol === null || item.id_pi_aol === '0' || item.id_pi_aol === '')">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49998 0L3.36206 5.68966H6.20687V8.79311H8.79309V5.68966H11.6379L7.49998 0Z" fill="white"/>
                    <path d="M8.27582 9.82758H6.72408C6.43961 9.82758 6.20685 10.0603 6.20685 10.3448C6.20685 10.6293 6.43961 10.862 6.72408 10.862H8.27582C8.5603 10.862 8.79306 10.6293 8.79306 10.3448C8.79306 10.0603 8.5603 9.82758 8.27582 9.82758Z" fill="white"/>
                    <path d="M8.27582 11.8965H6.72408C6.43961 11.8965 6.20685 12.1293 6.20685 12.4138C6.20685 12.6983 6.43961 12.931 6.72408 12.931H8.27582C8.5603 12.931 8.79306 12.6983 8.79306 12.4138C8.79306 12.1293 8.5603 11.8965 8.27582 11.8965Z" fill="white"/>
                    <path d="M8.27582 13.9655H6.72408C6.43961 13.9655 6.20685 14.1983 6.20685 14.4828C6.20685 14.7672 6.43961 15 6.72408 15H8.27582C8.5603 15 8.79306 14.7672 8.79306 14.4828C8.79306 14.1983 8.5603 13.9655 8.27582 13.9655Z" fill="white"/>
                  </svg>
                  Submit AOL
                </a> -->
              </div>
              <div class="col-md-1 text-right">
                <a href="javascript:void(0)" class="mr-2" style="font-size: 20px;" v-b-toggle="`collapse-${item.id_jobfile_cost}`">
                  <i class="fas fa-angle-down"></i>
                </a>&nbsp;
              </div>
            </div>
          </div>
          <b-collapse visible :id="`collapse-${item.id_jobfile_cost}`">
            <div class="card-body" style="background: #93EDC71A;">
              <div class="header-jobfile mt-2" v-for="(drHead, idxHead) in item.items" :key="idxHead">
                <div class="row">
                  <div class="col-6" style="display: flex;align-items: center;">
                    <div style="margin-left: 10px;">
                      <span style="color: #51C1AD;font-size: 16px;">Jobfile : </span> <a :href="urlCi + `/admin/jobfile/data?jobfile=${drHead.id_jobfile}`" style="color: #3A1AFF;font-size: 16px;color: #3A1AFF;font-weight: 600;">{{drHead.jobfile_no}}</a>
                    </div>
                  </div>
                  <div class="col-6" style="display: flex;align-items: center;justify-content: end;">
                    <!-- Using value -->
                    <b-button v-b-toggle="`collapse-${drHead.id_jobfile}`" class="m-1" style="background: none;border: none;transform: rotate(360deg);font-size: 20px;color: #51C1AD;">
                      <i class="fas fa-angle-down"></i>
                    </b-button>
                  </div>
                </div>

                <!-- Element to collapse -->
                <b-collapse :id="`collapse-${drHead.id_jobfile}`" class="pl-2 pr-2">
                  <table id="tcost1" class="table table-bordered table-striped small mt-2" width="100%">
                    <thead>
                      <tr style="background:#51C1AD;color:#fff;">
                        <th width="5%">No</th>
                        <th>Item</th>
                        <th>Qty</th>
                        <th style="text-align: right">Price</th>
                        <th>Item Description</th>
                        <th style="text-align: center">Department</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(dtItem, idx) in drHead.items" :key="idx">
                        <td>{{idx + 1}}</td>
                        <td>{{dtItem.item_name}}</td>
                        <td>{{dtItem.qty}}</td>
                        <td style="text-align:right">{{formatRupiah((dtItem.price).toString(), item.symbol)}}</td>
                        <td>{{dtItem.remarks}}</td>
                        <td style="text-align:center">{{dtItem.truck_name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-collapse>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>

    <!-- pagination -->
    <div class="row">
      <div class="col-md-5">
      </div>
      <div class="col-sm-12 col-md-7">
        <vue-ads-pagination
            :total-items="totalData"
            :max-visible-pages="5"
            :items-per-page="10"
            :page="page"
            :loading="loading"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="page = button.page"
                />
            </template>
        </vue-ads-pagination>
      </div>
    </div>
    <!-- end pagination -->
  </div>
</template>
<script>
// import Vuetable from 'vuetable-2/src/components/Vuetable'
// import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
// import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
// import DatePicker from 'vue2-datepicker';
import Swal from 'sweetalert2'
// import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import 'vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
// import DatePicker from 'vue2-datepicker'
// import Vue from 'vue'

// var url = '/quotation'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    // url = '/quotation?admin=true'
  }
}
// Vue.component('my-detail-row', DetailRow)

export default {
  components: {
    // Vuetable,
    // VuetablePagination,
    // VuetablePaginationInfo,
    // DatePicker,
    VueAdsPagination,
    VueAdsPageButton
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // filterText: '',
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + "/FavoriteJobOrder/2",
      urlPdf: '#',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'Quotation',
          href: '/quotation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 50, 100],
      perPage: 10,
      page: 0,
      pageActive: 1,
      totalData: 0,
      loading: false,
      fields: [
        {
          name: 'name',
          title: 'Name',
          // sortField: 'number',
        },
        {
          name: 'id_number',
          title: 'Job Order No',
          // sortField: 'subject',
        },
        {
          name: 'created_at_format',
          title: 'Created Date',
          // sortField: 'customer_id',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      momentFormat: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataBuying.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatEdit: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSingleBuying.date).format('YYYY-MM-DD') : ''
        },
      },
      moreParams: {},
      filterText: '',
      deleteData: {},
      totalPrice: 0,
      dataBuying: {
        currency: '',
        term: '',
        number: '',
        numberText: '',
        invoice: '',
        vendor: '',
        date: '',
        ppn: '',
        docCode: '',
        taxType: '',
        commercialRate: '',
        fiscalRate: '',
        inclusiveTax: false
      },
      types: [{
        type: '',
        item: [{
          item: '',
          price: '',
          // qty: '',
          remarks: '',
          item_id: '',
          curr:'',
          currency_id:''
        }]
      }],
      showTaxType: true,
      itemBuying: [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0
      }],
      newItemBuying: [{
        idJobfile: '',
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0
      }],
      inclusiveTax: 0,
      detail: [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        item_id: '',
        curr:''
      }],
      isManualNumber: false,
      isTruck: false,
      isCopyTransaction: "0",
      typeCopy: 'Selling',
      joborderCopy: '',
      numberCopy: '',
      remarksCopy: '',
      listJobOrderCopy: [],
      listNumberCopy: [],
      listType: [],
      urlCi: process.env.VUE_APP_CI_URL,
      css: {
        pagination: {
          activeClass: 'btn-primary text-white',
          pageClass: 'btn btn-sm ',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      momentFormatStartDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
        },
      },
      year: null,
      createdBy: null,
      statusData: 'All',
      startDateData: null,
      endDateData: null,
      branchData: [],
      marketingData: [],
      rangeData: 'customData',
      topCustomerData: 'Select Number',
      isJobOrderNo: true,
      compareData: false,
      isManualTopCustData: false,
      monthData: '01',
      showRate: false,
      yearData: null,
      nameFavorite: '',
      formatYearData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData).format('YYYY') : ''
        },
      },
      isLoading: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      jobfileTypeData: 'All',
      customerData: [],
      searchQuery: ''
    }
  },
  computed: {
    ...mapState('jobOrder', ['createdField']),
    ...mapState('customer', ['listCustomerQuo']),
    ...mapState('port', ['listPort']),
    ...mapState('vendor', ['listVendor']),
    ...mapState('truck', ['listTruck']),
    ...mapState('autonumber', ['listAutonumber']),
    ...mapState('currency', ['listCurrency','dataCurrency', 'dataDefaultCurrency']),
    ...mapState('item', ['listItem']),
    ...mapState('term', ['listTerm']),
    ...mapState('company', ['companyIsAol']),
    // ...mapState('jobOrderBuying', ['listDocCode', 'listTaxTypeBuying', 'dataSingleBuying', 'dataItemBuying']),
    ...mapState('multiBuyingDownPayment', ['listBuying', 'dataSingleBuying', 'dataItemBuying']),
    ...mapState('receiveableItem', ['listBuying']),
    ...mapState('percentage', ['listPercentage']),
    ...mapState('jobfile', ['listJobfile']),
  },
  mounted() {
    this.getCreatedField()
    this.getListCustomerQuo()
    this.getListPort()
    this.getListVendor()
    // this.getListAutonumber(25)
    this.p_getDefaultCurrency()
    this.initCurrency()
    // this.getListTerm()
    // this.getListItem()
    this.getListPercentage()
    this.getListTruck()
    this.getListDocCode()
    let idJoborder = window.location.href.split("/").splice(0, 6)[5];
    this.getListBuying({
        search: this.searchQuery,
        idJoborder: idJoborder,
        page: this.pageActive,
        per_page: this.perPage
      })
      .then((res) => {
        this.totalData = this.listBuying.total < 20 ? 20 : this.listBuying.total
      })
    this.isAol();
  },
  methods: {
    ...authMethods,
    errorAlertValidation: function (message = 'Terjadi Kesalahan') {
      this.$swal({
        title: message,
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },
    ...mapActions('jobOrder', ['getCreatedField']),
    ...mapActions('customer', ['getListCustomerQuo']),
    ...mapActions('port', ['getListPort']),
    ...mapActions('vendor', ['getListVendor']),
    ...mapActions('truck', ['getListTruck']),
    ...mapActions('autonumber', ['getListAutonumber', 'getListAutonumberFlexmod']),
    ...mapActions('currency', ['getListCurrency', 'getDefaultCurrency']),
    ...mapActions('item', ['getItemByPpn']),
    ...mapActions('term', ['getListTerm']),
    ...mapActions('quotation', ['saveQuotation']),
    ...mapActions('percentage', ['getListPercentage']),
    ...mapActions('company', ['isAol']),
    ...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
    ...mapActions('favoriteJobOrder', ['saveNewFavorite', 'loadFavorite', 'deleteFavJobOrder']),
    ...mapActions('jobOrderBuying', ['getListDocCode', 'getListTaxTypeBuying', 'saveBuying']),
    // ...mapActions('multiBuyingDownPayment', ['getListBuying', 'getBuying', 'updateBuying', 'getItemBuying', 'updateBuyingItem', 'saveNewBuyingItem', 'deleteBuyingItem', 'deleteBuying', 'submitAOL', 'submitAOLPI']),
    ...mapActions('receiveableItem', ['getListBuying']),
    ...mapActions('jobfile', ['getListJobfile']),
    setJson(payload) {
      this.json = JSON.parse(payload)
    },
    firstCapital(payload) {
      return payload[0].toUpperCase() + payload.slice(1)
    },
    getTgl() {
      this.dataBuying.date = moment(this.dataBuying.date).format('YYYY-MM-DD')
    },
    getTglEdit() {
      this.dataSingleBuying.cost_date = moment(this.dataSingleBuying.cost_date).format('YYYY-MM-DD')
    },
    onFilterSetMultipleKeywords(filterText) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      let strMarketing = []
      this.marketingData.forEach((data, idx) => {
        strMarketing.push(data.id_user)
      })

      let strCustomer = []
      this.customerData.forEach((data, idx) => {
        strCustomer.push(data.id)
      })

      if (this.jobfileType === '') {
        this.errorAlertValidation('Jobfile Type is required!')
      } else if (this.statusData === '') {
        this.errorAlertValidation('Status is required!')
      } else if (this.rangeData === '') {
        this.errorAlertValidation('Range is required!')
      } else if (this.rangeData !== '') {
        if (this.rangeData === 'customData') {
          if (this.startDateData === '' || this.startDateData == null) {
            this.errorAlertValidation('Start Date is required!')
          } else if (this.endDateData2 === '' || this.endDateData == null) {
            this.errorAlertValidation('End Date is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'monthlyData') {
          if (this.monthData === '' || this.monthData == null) {
            this.errorAlertValidation('Month is required!')
          } else if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'yearlyData') {
          if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        }
      }

      this.moreParams = {
        branch: localStorage.getItem('default_branch'),
        customer: strCustomer,
        jobfileType: this.jobfileTypeData,
        startDate: tempStartDateData,
        endDate: tempEndDateData,
        sales: strMarketing,
        status: this.statusData,
        perPage: 10,
        filter: true,
      }
      // console.log(this.moreParams, 'THIS PARAMSSS')
      // console.log(filterText)
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },

    formatDate(type) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      if (tempStartDateData === null || tempEndDateData === null) {
        return ''
      }
      return (
        this.monthNames[tempStartDateData.getMonth()] +
        ' ' +
        tempStartDateData.getFullYear() +
        ' - ' +
        this.monthNames[tempEndDateData.getMonth()] +
        ' ' +
        tempEndDateData.getFullYear()
      )
    },
    initCurrency() {
      try {
        this.getListCurrency()
          .then((res) => {
            // this.dataBuying.currency = 2
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    p_getDefaultCurrency() {
      try {
        this.getDefaultCurrency()
          .then((res) => {
            this.dataBuying.currency = this.dataDefaultCurrency.id
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    submitToAol(id) {
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOL({
          payload: id,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListBuying({
              search: this.searchQuery,
              idJoborder: idJoborder,
              page: this.pageActive,
              per_page: this.perPage
            })
            .then((res) => {
              this.totalData = this.listBuying.total < 20 ? 20 : this.listBuying.total
            })
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    submitToAolPI(id) {
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOLPI({
          payload: id,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListBuying({
              search: this.searchQuery,
              idJoborder: idJoborder,
              page: this.pageActive,
              per_page: this.perPage
            })
            .then((res) => {
              this.totalData = this.listBuying.total < 20 ? 20 : this.listBuying.total
            })
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    doFilter() {
      this.onFilterSet(this.filterText)
    },
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      // this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },
    onAction(action, rowData, rowIndex) {
      if (action === 'Back') {
        this.$router.push({
          name: 'MultiPurchaseInvoice List'
        })
      }
    },
    searchData() {
      let idJoborder = window.location.href.split("/").splice(0, 6)[5];
      this.getListBuying({
        search: this.searchQuery,
        idJoborder: idJoborder,
        page: this.pageActive,
        per_page: this.perPage
      })
      .then((res) => {
        this.totalData = this.listBuying.total < 10 ? 10 : this.listBuying.total
      })
    },
    pageChange (page) {
      this.page = page;
      console.log(page);
    },

    rangeChange (start, end) {
      // this.page = (end / this.perPage)
      let idJoborder = window.location.href.split("/").splice(0, 6)[5];
      this.getListBuying({
        search: this.searchQuery,
        idJoborder: idJoborder,
        page: (end / this.perPage),
        per_page: this.perPage
      })
      .then((res) => {
        this.totalData = this.listBuying.total < 10 ? 10 : this.listBuying.total
      })
        console.log(start, end);
    },
    formatRupiah(angka, prefix){
      // console.log(angka, 'THIS ANGKAA')
      angka = angka.replace(/\./g, ',')
			let numberString = angka
      // .replace(/[^,\d]/g, '').toString()
      // console.log(numberString, 'THIS ANGKAA 2')
			let split   		= numberString.split(',')
			let sisa     		= split[0].length % 3
			let rupiah     		= split[0].substr(0, sisa)
			let ribuan     		= split[0].substr(sisa).match(/\d{3}/gi)

			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				let separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}

			rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix === undefined ? rupiah : (rupiah ? prefix + ' ' + rupiah : '');
		},
  },
}
</script>
<style>
.created-field svg {
  color: #51c1ad;
}
.modal-xl {
  max-width: 95% !important;
}
.mx-datepicker .mx-input-wrapper .mx-input {
  height: 31px !important;
}
.header-jobfile {
  min-height: 60px;
  background: #FFF;
  display: grid;
  align-items: center;
}
a.not-collapsed i {
  transform: rotate(180deg);
}
button.not-collapsed i {
  transform: rotate(180deg);
}
.vue-ads-leading-loose {
  display: none;
}
</style>

